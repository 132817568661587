@import "./colors";
@import "./bootstrap/variables";
@import "./bootstrap/mixins";

footer.footer{
  background-color: $black_level_1;
  padding: 38px 0;
  > div.container{
      @include media-breakpoint-down(sm){
          width:100%;
      }
  }
  div.footer_main_block{
    div.left_block, div.middle_block, div.right_block{
      display: inline-block;
      vertical-align: middle;
      @media screen and (max-width:767px){
        display: block;
        vertical-align: top;
      }
    }
    div.left_block{
      @media screen and (max-width:767px){
        text-align: center;
      }
      a.logo_link{
        display: inline-block;
        text-decoration: none;
        img{
          width: 150px;
        }
      }
    }
    div.middle_block{
      width: calc(100% - 505px);
      margin: 0 30px;
      @media screen and (max-width:767px){
        width:100%;
        margin:30px 0;
      }
      p.para1, p.para2{
        font-size: .875rem;
        color: $white;
      }
      p.para1{
        margin-bottom: 5px;
      }
      p.para2{
        margin:0;
        line-height: 2;
      }
    }
    div.right_block{
      float:right;
      margin-top: 26px;
      @media screen and (max-width:767px){
        margin-top: 0;
        float: none;
      }
      ul.right_block_list{
        margin:0;
        padding:0;
        > li{
          list-style: none;
          margin:0 0 5px 0;
          padding:0;
          > a{
            text-decoration: none;
            font-size: .875rem;
            color: $white;
          }
          p.copyright{
            margin:0;
            color: $white;
            font-size: .875rem;
          }
        }
      }
    }
  }

  div.designed_by_block{
    margin-top: 20px;
    padding-top:10px;
    opacity: .6;
    border-top:1px solid white;
    text-align: right;
    a.designed_by{
      color: white;
      text-decoration: none;
      font-style: italic;
      font-size: .75rem;
      &:hover{
        text-decoration: underline;
        text-shadow: 0 1px 1px hsla(0, 0%, 100%, 1);
      }
    }
  }
}
