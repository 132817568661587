@import "./colors";
@import "./bootstrap/variables";
@import "./bootstrap/mixins";
@import "./header";
@import "./footer";

@import "./aside_for_about";

body{
  font-family: "微軟正黑體", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
}
img{
    max-width: 100% !important;
}

div.add_gradient_bg{
  /*
    background-image: linear-gradient($black, $black_level_1 30px, $black_level_1 calc(100% - 30px), $black 100%);
    */
    background-image: linear-gradient(-180deg, #2F2F2F 0%, #3D3A39 calc(6% + 60px), #3D3A39 calc(94% - 60px), #262626 100%);
    min-height: calc(100vh - 160px);
    padding-top:60px;
}

span.draft_label{
    position: absolute;
    top:0;
    right:0;
    border-radius: 5px;
    background-color: rgb(112, 175, 0);
    color: $white;
    font-size: .75rem;
    display: inline-block;
    padding:{
        left:5px;
        right:5px;
    };
}

ul.admin_list{
    margin:0;
    padding:2px 9px 6px;
    position: fixed;
    right:5px;
    top:5px;
    background-color: $orange;
    border-radius: 5px;
    overflow: hidden;
    line-height: 1;
    box-shadow: 1px 1px 5px gray;
    z-index: 9;
    @media screen and (max-width: 767px){
        top: auto;
        bottom: 5px;
    }
    > li{
        margin:0;
        padding:0;
        display: inline-block;
        list-style: none;
        vertical-align: middle;
        font-size: .75rem;
        &.vertical_line{
            width:1px;
            background-color: $white;
            height: 10px;
            margin:{
                left:3px;
                right:3px;
            };
        }
        > a{
            color: $white;
            display: inline-block;
            font-size: .75rem;
        }
    }
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  .pace-progress{
      background: $orange_level_1;
      position: fixed;
      z-index: 2000;
      top: 0;
      right: 100%;
      width: 100%;
      height: 3px;
  }
}

.pace-inactive {
  display: none;
}
