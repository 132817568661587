@import "./colors";
@import "./bootstrap/variables";
@import "./bootstrap/mixins";

aside.aside_for_about{
  ul.aside_list{
    margin:0;
    padding:0;
    > li{
      margin:0 0 30px 0;
      padding:0;
      list-style: none;
      display: block;
      &:last-child{
        margin-bottom: 0;
      }
      > a{
        color: $white;
        font-size: 1.25rem;
        display: inline-block;
        width:100%;
        padding:5px 12px 5px 12px;
        text-decoration: none;
        background-color: $gray_level_4;
        border-left: 8px solid transparent;
        &.on{
          border-left: 8px solid $orange_level_1;
        }
        &:hover{
          @include media-breakpoint-up(md){
            border-left: 8px solid $orange_level_1;
          }
        }
      }
    }
  }
}
