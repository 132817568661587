@import "./colors";
@import "./bootstrap/variables";
@import "./bootstrap/mixins";

header.header{
  background-color: $black_level_1;
  position: fixed;
  width:100%;
  z-index: 4;
  > div.container{
      @include media-breakpoint-down(sm){
          width:100%;
      }
  }
  @include media-breakpoint-down(sm){
    padding-top:12px;
    padding-bottom: 12px;
  }
  nav.main_nav{
    padding:0;
    background-color: transparent;
    display: block;
    > div.mobile_head{
      button.navbar-toggler{
        margin-top:0px;
        cursor: pointer;
        height: 36px;
        right:0;
        border:0;
        padding:{
            left:6px;
            right:6px;
        };
        span.navbar-toggler-icon{
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
        }
      }
      a.navbar-brand{
        width:156px;
        height: 36px;
        padding:0;
        margin-right: 0;
        img{
          width:100%;
        }
      }
    }

    > a.navbar-brand{
      margin-top: 7px;
      margin-right: 0;
      padding:0;
      img.img_logo{
          width: 254px;
      }
    }
    > div.collapse_toggle{
      @include media-breakpoint-up(md){
        display: inline-block !important;
        float:right;
      }
      > ul.nav{
          margin-top: 5px;
        > li.nav-item{
          margin-right: 20px;
          @include media-breakpoint-down(md){
            margin-right: 5px;
            margin-left:5px;
          }
          &:last-child{
            margin-right: 0;
          }
          > a.nav-link{
            color: $white;
            display: inline-block;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            text-align: center;
            min-width: inherit;
            width:auto;
            cursor: pointer;
            border-bottom: 5px solid transparent;
            padding:{
              top:13px;
              bottom:13px;
              left:15px;
              right:15px;
            };
            @include media-breakpoint-down(lg){
              padding-left:5px;
              padding-right:5px;
            }
            @include media-breakpoint-down(md){
              padding-left:0;
              padding-right:0;
            }
            @include media-breakpoint-down(sm){
              padding-top:10px;
              padding-bottom: 10px;
            }
            &.on{
              border-bottom: 5px solid $white_level_2;
              @include media-breakpoint-down(sm){
                color: $white;
                border-bottom:0;
              }
            }
            &:hover{
              @include media-breakpoint-up(md){
                border-bottom: 5px solid $white_level_2;
              }
            }
          }
          > div.dropdown-menu{
              @include media-breakpoint-up(md){
                  margin-top: 0;
                  border-radius:0;
                  padding:0;
                  min-width: inherit;
                  width: 100%;
                  box-shadow: 0 0 2px $gray_level_1;
                  > a.dropdown-item{
                      color: $black;
                      background-color: $gray_level_6;
                      border-bottom: 1px solid $gray_level_1;
                      text-align: center;
                      padding:10px;
                      &:hover, &.on{
                          background-color: $gray_level_7;
                      }
                      &.on{
                          font-weight: bold;
                      }
                  }
              }
            @include media-breakpoint-down(sm){
              border:0;
              margin:0 0 10px 0;
              padding:0;
              background-color: transparent;
              a.dropdown-item{
                margin-bottom: 10px;
                color: $white;
                &:hover, &:focus, &:active{
                    background:none;
                    color: $orange_level_1;
                }
                &.on{
                    color: $orange_level_1;
                    font-weight: bold;
                }
                &:last-child{
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
